@charset "utf-8";
// Core variables and mixins
@import "base/variables.scss";
@import "base/mixins.scss";

// Custom Fonts

  
    @mixin heading-font {
      font-family: "Montserrat", -apple-system, BlinkMacSystemFont, "Segoe UI",
      Roboto, "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji",
      "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
    }
  
    @mixin script-font {
      font-family: "Kaushan Script", -apple-system, BlinkMacSystemFont, "Segoe UI",
      Roboto, "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji",
      "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
    }
  
    @mixin serif-font {
      font-family: "Droid Serif", -apple-system, BlinkMacSystemFont, "Segoe UI",
      Roboto, "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji",
      "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
    }
  
    @mixin body-font {
      font-family: "Roboto Slab", -apple-system, BlinkMacSystemFont, "Segoe UI",
      Roboto, "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji",
      "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
    }
  


// Custom colors
$primary: #fed136 !default;
$white: #fff !default;
$black: #000 !default;

// Custom Images
$header-image: "../img/pacifica_view.jpg";
$contact-image: "../img/map-image.png";

// Global CSS
@import "base/page.scss";

// Components
@import "components/buttons.scss";
@import "components/navbar.scss";
@import "components/client-scroll.scss";

// Layout
@import "layout/masthead.scss";
@import "layout/services.scss";
@import "layout/portfolio.scss";
@import "layout/timeline.scss";
@import "layout/team.scss";
@import "layout/contact.scss";
@import "layout/footer.scss";
